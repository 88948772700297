.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: "Regular";   /*Can be any text*/
  src: local("AvertaforTBWA-Regular"),
    url("./AvertaforTBWA-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Extrabold";   /*Can be any text*/
  src: local("AvertaforTBWA-Extrabold"),
    url("./AvertaforTBWA-Extrabold.ttf") format("truetype");
}

*{
  font-family: "Regular";
}






.login-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #fff181;
}

.login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

label {
  margin-top: 10px;
  font-weight: bold;
}

input {
  margin-bottom: 20px;
  padding: 10px;
  border-radius: 0px;
  border: none;
}

.button-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.login-button {
  background-color: #43454b;
  color: #fff;
  width: 100px;
  padding: 10px 20px;
  border-radius: 0px;
  border: none;
  cursor: pointer;
}

.signup-button {
  background-color: #fff;
  width: 100px;
  color: #43454b;
  padding: 10px 20px;
  border-radius: 0px;
  border: 1px solid #43454b;
  cursor: pointer;
}


.signup-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #fff181;
}

.signup-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

label {
  margin-top: 10px;
  font-weight: bold;
}

input {
  margin-bottom: 20px;
  padding: 10px;
  border-radius: px;
  border: none;
}

.button-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.signup-button {
  background-color: #43454b;
  color: #fff;
  padding: 10px 20px;
  border-radius: 0px;
  border: none;
  cursor: pointer;
}

.privacy-screen {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #FFF181;
  z-index: 2;

}

.menu-item {
  width: 70vw;
  background-color: #43454b;
  padding-top: 5vh;
  padding-bottom: 5vh;
  z-index: 4;
  border-right: 5px solid white;
}

input {
  text-align: center;
}

::-webkit-input-placeholder {
  text-align: center;
}

:-moz-placeholder {
  text-align: center;
}